import React from 'react';
import './index.scss';

const AboutMayo = () => {
    return (
        <div className='about-mayo-container'>
            <div className='about-mayo-content container'>
                <div className='about-mayo-text'>
                    <h2>About Mayo Clinic</h2>
                    <p>
                        Mayo Clinic is a non-profit worldwide leader in healthcare.
                        Physicians from every medical specialty work together to advance
                        medical knowledge through research and education, and apply that
                        knowledge to medical care.
                    </p>
                    <p>
                        The Mayo Clinic Care Network represents non-ownership
                        relationships with organisations that share the same desire to
                        serve patients and improve the delivery of healthcare.
                    </p>
                    <a
                        href='https://www.mayoclinic.org/about-mayo-clinic/care-network'
                        target='blank'
                        className='learn-more-btn'>
                        Learn More
                    </a>
                </div>
            </div>

            <div className='about-mayo-image'>
                <img src='/img/mayo/mayo.avif' alt='Mayo Clinic' />
                <div className='d-none d-md-block' style={{
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    mixBlendMode: "darken"
                }}>
                    <div className="bg-white p-5 rounded-circle"></div>
                    <div className="bg-white p-5 rounded-circle"></div>
                    <div className="" style={{
                        backgroundColor: "#013b59",
                        position: "absolute",
                        zIndex: -1,
                        top: 50,
                        width: "48px",
                        height: "96px"
                    }}></div>
                </div>
                <div className='d-flex d-md-none' style={{
                    position: "absolute",
                    zIndex: 1,
                    right: 0,
                    top: 0,
                    mixBlendMode: "color-dodge"
                }}>
                    <div className="bg-black p-4 rounded-circle"></div>
                    <div className="bg-black p-4 rounded-circle"></div>
                    <div className="" style={{
                        backgroundColor: "white",
                        position: "absolute",
                        zIndex: -1,
                        right: 25,
                        width: "48px",
                        height: "24px"
                    }}></div>
                </div>
            </div>
        </div>
    );
};

export default AboutMayo;
