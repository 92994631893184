import React from 'react';

import './index.scss';

import BaseOurCollaborationCard from './BaseOurCollaborationCard';
import MayoClinic from '../index';

import AboutMayo from './AboutMayo';

const OurCollaborationItems = [
    {
        url: './../img/mayo/intro-1.jpg',
        iconUrl: '/img/mayo/icon-1.png',
        color: '#39B5B2',
        title: 'Extensive Cutting-Edge Medical Knowledge',
        description:
            'Our doctors now gain special access to the latest medical insights, expertise and resources from the #1 ranked hospital in the USA.',
    },
    {
        url: './../img/mayo/intro-2.jpg',
        iconUrl: '/img/mayo/icon-2.png',
        color: '#003C50',
        title: 'Specialised Care for Rare or Complex Conditions',
        description:
            'Direct consultation with Mayo Clinic experts, specialised in trending intricate cases.',
    },
    {
        url: './../img/mayo/intro-3.jpg',
        iconUrl: '/img/mayo/icon-3.png',
        color: '#003C50',
        title: 'Personalised Treatment Plans',
        description:
            'We use the latest research and recommendations from Mayo Clinic to craft tailored treatment plans',
    },
    {
        url: './../img/mayo/intro-4.jpg',
        iconUrl: '/img/mayo/icon-4.png',
        color: '#39B5B2',
        title: 'Enhanced Quality Care with No Extra Cost',
        description:
            'Receive top-notch care that blends our understanding of your medical needs with Mayo Clinic’s world-class expertise at no additional cost.',
    },
];

const OurCollaboration = () => {
    return (
        <MayoClinic>
            <div className='container our-collaboration'>
                <div className='our-collaboration__title text-black'>Gain access to</div>

                <div className='our-collaboration__wrapper'>
                    {OurCollaborationItems.map((item, index) => (
                        <BaseOurCollaborationCard key={index} {...item} />
                    ))}
                </div>

                <div className='our-collaboration__divider'></div>

                <div className='our-collaboration__title'>
                    Ready to lead the way in world-class, patient-centered care.
                </div>

                <div className='our-collaboration__video'>
                    <iframe
                        width='700'
                        height='400'
                        src='https://www.youtube.com/embed/F0LnApNlcRE?si=fE4U2MgyHi-culKo'
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        referrerPolicy='strict-origin-when-cross-origin'
                        allowFullScreen></iframe>
                </div>
            </div>

            <AboutMayo />
        </MayoClinic>
    );
};

export default OurCollaboration;
