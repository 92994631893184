import React from 'react';

import MayoClinicBanner from './components/MayoClinicBanner';
import MayoIntroduction from './components/MayoIntroduction';
import MayoActions from './components/MayoActions';
import { Helmet } from 'react-helmet';
const MayoClinic = ({ children }) => {
    return (
        <>
            <Helmet>
                <title>Mayo Clinic Care Network | KPJ Healthcare</title>
                <meta name="keywords" content="KPJ Healthcare, Mayo Clinic Care Network, KPJ Healthcare Mayo Clinic collaboration, Mayo Clinic healthcare network, KPJ hospitals Mayo Clinic, healthcare collaboration Malaysia, Mayo Clinic expertise Malaysia, personalized healthcare Malaysia, medical services Malaysia, healthcare providers Malaysia, private healthcare Malaysia, patient care KPJ Mayo Clinic, Mayo Clinic support KPJ Healthcare, health screenings Malaysia, health packages Malaysia, Mayo Clinic diagnosis support, healthcare wellness Malaysia, medical consultation Mayo Clinic, global healthcare collaboration, specialist doctors Malaysia Mayo Clinic, Mayo Clinic global healthcare, healthcare services Malaysia Mayo Clinic, collaborative healthcare solutions" />
                <meta name="description" content="KPJ Healthcare Mayo Clinic Collaboration" />
            </Helmet>
            <MayoClinicBanner />
            <MayoIntroduction />
            <MayoActions />
            {children}
        </>
    );
};

export default MayoClinic;
