import React from 'react';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const MayoIntroduction = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const handleOnNavigate = () => {
        // ? Open new tab
        window.open(
            'https://newsnetwork.mayoclinic.org/discussion/two-kpj-healthcare-hospitals-in-malaysia-join-mayo-clinic-care-network/',
            '_blank'
        );
    };
    return (
        <div className='mayo-container container'>
            <div className='mayo-content flex-column-reverse flex-lg-row'>
                <div className='mayo-images'>
                    <img
                        src='/img/mayo/image-1.png'
                        alt='Hospital 1'
                        className='mayo-image'
                    />
                    <img
                        src='/img/mayo/image-2.png'
                        alt='Hospital 2'
                        className='mayo-image'
                    />
                </div>
                <div className='mayo-text'>
                    <h2 className='py-3 py-md-0'>First in Malaysia</h2>
                    <p>
                        KPJ Healthcare Berhad is the FIRST and ONLY healthcare provider in Malaysia to establish
                        a collaboration with Mayo Clinic, USA, a globally recognised
                        leading institution known for its excellence in healthcare,
                        research, and education.
                    </p>
                    <p>
                        <strong>KPJ Damansara Specialist Hospital</strong> and{' '}
                        <strong>Damansara Specialist Hospital 2</strong> are now members
                        of the <strong>Mayo Clinic Care Network</strong>, marking a
                        significant step towards progress in medical research, innovation,
                        and patient care.
                    </p>
                    <button
                        onClick={handleOnNavigate}
                        className='btn px-5 py-2 text-white fw-bold d-none d-md-block'
                        style={{
                            backgroundColor: '#003C50',
                            borderRadius: '32px',
                            fontSize: '14px',
                        }}>
                        Learn More
                    </button>
                    <div className='d-flex justify-content-center d-md-none'>
                        {location.pathname === "/mccn/our-collaboration"
                            ? <button
                                onClick={handleOnNavigate}
                                className='btn px-5 py-2 text-white fw-bold'
                                style={{
                                    backgroundColor: '#003C50',
                                    borderRadius: '32px',
                                    fontSize: '14px',
                                }}>
                                Learn More
                            </button>
                            : <>
                                <button
                                    onClick={() => {
                                        localStorage.setItem("position", window.scrollY);
                                        navigate("/mccn/our-collaboration")
                                    }}
                                    className='btn px-5 py-2 text-white fw-bold d-flex align-items-center d-md-none'
                                    style={{
                                        backgroundColor: '#003C50',
                                        borderRadius: '32px',
                                        fontSize: '14px',
                                    }}>
                                        <div className='me-1' style={{
                                            maskImage: "url('/img/mayo/arrow.svg')",
                                            maskSize: "cover",
                                            height: "21px",
                                            width: "21px",
                                            backgroundColor: "white",
                                            rotate: "180deg"
                                        }}></div>
                                        <div>Back</div>
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MayoIntroduction;
