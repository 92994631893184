import React from 'react';

import Banner from './banner.png';
import BannerMobile from './banner-mobile.png';
import kpjLogo from './kpj-logo.png';
import mayoLogo from './mayo-logo.png';
import './index.scss';

const MayoClinicBanner = () => {
    return (
        <>
            <>
                <div
                    className='mayo-clinic-banner web-view d-flex'
                    style={{
                        backgroundImage: `url(${Banner})`,
                        position: 'relative',
                        backgroundSize: 'cover',
                    }}>
                    <div className='container row'>
                        <div className="col-3"><img src={kpjLogo} alt="" /></div>
                        <div className="col-3"><img src={mayoLogo} alt="" /></div>
                        <div className="col-6"></div>
                        <h1 className='mayo-clinic-banner-text col-6 mt-5'>
                            Your Well-being<br/>is at the Heart of<br/>Our Collaboration.
                        </h1>
                    </div>
                </div>
                <div
                    className='mayo-clinic-banner mobile-view'
                    style={{
                        backgroundImage: `url(${BannerMobile})`,
                        position: 'relative',
                        backgroundSize: 'cover',
                        backgroundPosition: '-100px bottom',
                    }}>
                    <div className='container row'>
                        <div className="col-6 mt-5"><img src={kpjLogo} alt="" /></div>
                        <div className="col-6 mt-5"><img src={mayoLogo} alt="" /></div>
                        <h2 className='mayo-clinic-banner-text col-12 text-center mt-3 text-lg'>
                            Your Well-being is at the Heart of Our Collaboration.
                        </h2>
                    </div>
                </div>
            </>
        </>
    );
};

export default MayoClinicBanner;
