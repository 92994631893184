import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss';

const MayoActionsItems = [
    {
        title: 'Our Proud Collaboration',
        path: '/mccn/our-collaboration',
    },
    {
        title: 'Frequently Asked Questions (FAQ)',
        path: '/mccn/faq',
    },
    {
        title: 'Mayo Clinic Care Network',
        path: '/mccn/health-library',
    },
];

const MayoActions = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleOnNavigate = (path) => {
        localStorage.setItem("position", window.scrollY);
        navigate(path);
    };

    const isActivePath = (path) => {
        return location.pathname === path;
    };

    // useEffect(() => {
    //     return () => {
    //         requestAnimationFrame(() => {
    //             setTimeout(() => {
    //                 window.scrollTo({
    //                     top:  window.scrollY,
    //                     behavior: "instant"
    //                 });
    //             }, 0)
    //         })
    //     };
    // }, [location.pathname]);

    useEffect(() => {
        requestAnimationFrame(() => {
            setTimeout(() => {
                window.scrollTo({
                    top: Number(localStorage.getItem("position")) || 0,
                    behavior: "instant"
                });
            }, 0)
        })
    }, [location.pathname])

    return (
        <div className='container'>
            <div className='mayo-actions__divider'>
                {/* {localStorage.getItem("position")} */}
            </div>

            <div className='mayo-actions__wrapper d-none d-md-grid'>
                {MayoActionsItems.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => handleOnNavigate(item.path)}
                        className='btn py-2 text-white fw-bold'
                        style={{
                            backgroundColor: isActivePath(item.path)
                                ? '#967F65'
                                : '#003C50',
                            borderRadius: '32px',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                        }}>
                        {item.title}
                    </button>
                ))}
            </div>

            <div className={`mayo-actions__wrapper flex-row row ${location.pathname === "/mccn/our-collaboration" ? "" : "d-none"} d-md-none`} style={{
            }}>
                {MayoActionsItems.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => handleOnNavigate(item.path)}
                        className='btn text-white fw-bold col'
                        style={{
                            backgroundColor: isActivePath(item.path)
                                ? '#967F65'
                                : '#003C50',
                            borderRadius: '16px',
                            fontSize: '12px',
                            whiteSpace: '',
                            height: "86px"
                        }}>
                        {item.title}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default MayoActions;
