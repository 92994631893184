import React, { useState, useEffect } from 'react';
import './index.scss';

import MayoClinic from '../index';

import { useNavigate } from 'react-router-dom';
const { REACT_APP_PUBLIC_API_URL } = process.env;

const imageAndUrlDefinitions = {
    diseases: {
        image: '/img/mayo/info-1.png',
        url: '/mccn/diseases',
        title: 'Diseases',
        description:
            'Browse or search diseases and conditions and learn more about treatment options',
    },
    firstAid: {
        image: '/img/mayo/info-2.png',
        url: '/mccn/first-aid',
        title: 'First Aid',
        description: 'Browse or search and learn more about first aid treatment',
    },
    symptoms: {
        image: '/img/mayo/info-3.png',
        url: '/mccn/symptoms',
        title: 'Symptoms',
        description: 'Find causes and solutions for your symptoms',
    },
    testsProcedures: {
        image: '/img/mayo/info-4.png',
        url: '/mccn/tests-procedures',
        title: 'Tests & Procedures',
        description:
            'Learn more about tests or procedures related to your current health concerns',
    },
    faq: {
        image: '/img/mayo/info-5.png',
        url: '/mccn/faqs',
        title: 'FAQ',
        description: 'Browse or search any definitions of health',
    },
};

const formatHealthTopics = (data = []) => {
    return data.map((topic) => {
        const imageAndUrl = imageAndUrlDefinitions[topic.name] || {};

        return {
            ...topic,
            ...imageAndUrl,
        };
    });
};

const MayoHealthLibrary = () => {
    const navigate = useNavigate();
    const [healthTopics, setHealthTopics] = useState([]);

    useEffect(() => {
        const fetchHealthTopics = async () => {
            const response = await fetch(`${REACT_APP_PUBLIC_API_URL}/sections`);
            const data = await response.json();
            setHealthTopics(formatHealthTopics(data?.data || []));
        };

        fetchHealthTopics();
    }, []);
    return (
        <MayoClinic>
            <div className='container'>
                <div className='mayo-health-library__title text-black'>
                    Welcome to the Mayo Clinic Health Information Library
                </div>

                <div className='mayo-health-library__description'>
                    Here you will find information and more related to a variety of
                    topics. You can browse content by category and then alphabetically or
                    simply use the search bar to find what you are looking for. Our goal
                    is to provide you with the information you need and help you
                    understand your diagnosis or procedure. All the content of the library
                    is provided from Mayo Clinic.
                    <br />
                    <br />
                    The information on these pages should not be considered medical advice
                    and is for informational purposes only.
                </div>

                <div className='mayo-health-library'>
                    {healthTopics.map((topic, index) => (
                        <a
                            href={topic.url}
                            key={index}
                            className='health-item'
                            // onClick={() => navigate(topic.url)}
                        >
                            <img
                                src={topic.image}
                                alt={topic.title}
                                className='health-image'
                            />
                            <div className='health-text'>
                                <h3>{topic.title}</h3>
                                <p>{topic.description}</p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </MayoClinic>
    );
};

export default MayoHealthLibrary;
