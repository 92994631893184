import React, { useState } from 'react';

import MayoClinic from '../index';

import './index.scss';

const faqData = [
  {
    question: "What is the Mayo Clinic Care Network?",
    answer:
      "The Mayo Clinic Care Network is a select group of independent health systems that are carefully evaluated and granted unique access to Mayo Clinic knowledge, expertise, and resources. As part of the network, KPJ Healthcare physicians at KPJ Damansara Specialist Hospital and Damansara Specialist Hospital 2 can quickly access the latest research, diagnostic, and treatment resources from the Mayo Clinic when they believe it will benefit their patients. Furthermore, the partnership with Mayo Clinic will support KPJ Healthcare in achieving its goals to improve health services.",
  },
  {
    question: "Has KPJ Healthcare been acquired by Mayo Clinic?",
    answer:
      "No. This agreement does not constitute an acquisition or a merger. Mayo Clinic Care Network members, such as KPJ Healthcare, remain independent healthcare providers dedicated to serving the needs of their communities.",
  },
  {
    question: "Why did Mayo Clinic choose KPJ Healthcare to join the network?",
    answer:
      "KPJ Healthcare was selected to join the Mayo Clinic Care Network due to its commitment to providing high-quality, patient-focused care services. Additionally, KPJ Healthcare successfully passed Mayo Clinic’s comprehensive evaluation process, which includes an in-depth review of the organisation, clinical and business practices, as well as quality, safety, and service efforts.",
  },
  {
    question:
      "What does this mean for KPJ Healthcare physicians and their patients?",
    answer:
      "With access to Mayo Clinic’s research, diagnostic tools, and treatment resources, KPJ Healthcare physicians can provide advanced care to more patients without the need for them to travel far. This allows KPJ Healthcare physicians to combine their understanding of their patients’ specific medical needs with the resources of the Mayo Clinic to develop the best care plans.",
  },
  {
    question:
      "What resources are available to KPJ Healthcare through the Mayo Clinic Care Network?",
    answer:
      "All service providers will have access to AskMayoExpert and patient education resources. Cancer specialists will have access to the Mayo Clinic eBoards. Below are more details about these resources:<br><br>" +
      "<strong>AskMayoExpert</strong> – An online medical reference tool that provides disease management, treatment guidelines, treatment recommendations, patient education, and reference materials for various medical conditions.<br>" +
      "<strong>eBoards</strong> – Live video conferencing enabling KPJ Healthcare’s medical team to review and discuss complex cancer cases with a team of Mayo Clinic specialists and doctors from other Mayo Clinic Care Network member organisations.<br>" +
      "<strong>Health Care Consulting</strong> – A programme designed to share Mayo Clinic’s experience and expertise, helping us achieve operational, financial, and patient care excellence.<br>" +
      "<strong>Ongoing Medical Education</strong> – Accredited, on-demand presentations, medical lectures, and detailed surgical procedures by Mayo Clinic physicians and scientists are available to KPJ Healthcare physicians to enhance provider education and explore new trends and developments in the medical field.",
  },
  {
    question: "Will Mayo Clinic doctors work for KPJ Healthcare?",
    answer:
      "No. When KPJ Healthcare physicians seek clinical advice or have questions about complex patient care, they can connect with Mayo Clinic specialists for consultation.",
  },
  {
    question: "How can I get more information?",
    answer: "Please contact our Medical Concierge team at <a href='tel:+60193243208'>+6019-324 3208</a>.",
  },
];

const MayoFaq = () => {
    const [openedFaqs, setOpenedFaqs] = useState([]);
    const setOpenFaq = (index) => {
        if (openedFaqs.includes(index)) {
            setOpenedFaqs(openedFaqs.filter(openedFaq => openedFaq !== index))
        } else {
            setOpenedFaqs([...openedFaqs, index]);
        }
    }
    return (
        <MayoClinic>
            <div className='mayo-faq'>
                <h2 className='text-black'>Frequently Asked Questions (FAQ)</h2>
                {faqData.map((faq, index) => {
                    return <div className="d-flex">
                        <div onClick={() => {setOpenFaq(index)}} className='me-1 flex-shrink-0 d-md-none' style={{
                            maskImage: "url('/img/mayo/arrow.svg')",
                            maskSize: "cover",
                            height: "21px",
                            width: "21px",
                            backgroundColor: "rgb(150, 127, 101)",
                            rotate: openedFaqs.includes(index) ? "90deg" : ""
                        }}></div>
                        <div className="faq-item">
                            <h3 onClick={() => {setOpenFaq(index)}}>Q: {faq.question}</h3>
                            <p dangerouslySetInnerHTML={{__html: faq.answer}} className={`d-md-block ${openedFaqs.includes(index) ? "" : "d-none"}`}></p>
                        </div>
                    </div>
                })}
            </div>
        </MayoClinic>
    );
};

export default MayoFaq;
